<template>
  <CardToolbox>
    <sdPageHeader title="Ongeldige aanvraag">

    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="30">

      <a-col :md="24" :xs="24">
        <sdCards headless>
          Mogelijk heeft u een link van een van onze klanten ontvangen, CONTRACKT is een softwaredienst om contracten aan te kunnen bieden. Neem contact op met uw contactpersoon om een geldige link te ontvangen
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {defineComponent} from 'vue';
import {Main, CardToolbox} from '../styled';

const Sign = defineComponent({
  name: 'Sign',
  components: {Main, CardToolbox},
  data() {
    return {
      showEditDrawer: false,
    }
  },
});

export default Sign;
</script>

<style>

.signature canvas {
  width: 450px;
  height: 200px;
}

</style>
