<template>
  <Suspense>
    <sdCards v-if="!calculations"  title="Kostenspecificatie">
      <a-skeleton active/>
    </sdCards>
    <sdCards v-else title="Kostenspecificatie">
      <a-collapse v-for="(item, index) in calculations.calculation.connectionCalculations"
                  :key="index" default-active-key="0" :bordered="false">
        <template #expandIcon="props">
          <sdFeatherIcons :type="props.isActive ? 'chevron-down' : 'chevron-right'" size="14"/>
        </template>
        <a-collapse-panel key="1" :style="customStyle">
          <template #header>
            <div class="lead collapse-title" style="width:100%;display:inline-block;">
              <i v-if="item.connection.marketSegment === 'ELECTRICITY'" class="fas fa-bolt" style="float:left;font-size: 16px; color:#000000"></i>
              <i v-if="item.connection.marketSegment === 'GAS'" class="fas fa-burn" style="float:left;font-size: 16px; color:#000000"></i>
              <h4 class="ml-3 mr-1" style="float:left">{{item.connection.marketSegment === 'ELECTRICITY'? 'Stroom':''}}{{item.connection.marketSegment === 'GAS'? 'Gas':''}}: {{item.connection.ean}}</h4>
              <div class="text-right mr-5"></div>
            </div>
          </template>
          <div>
            <hr/>
            <div>
              {{ calculations.product.name }}
            </div>
            <hr/>
            <a-row>
              <a-col
                  :xs="24"
                  class="mt-1"
              >
                <strong>Leveringskosten</strong>
              </a-col>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'SINGLE' && item.connection.marketSegment === 'ELECTRICITY'"
              >
                <a-col
                    :xs="16"
                >
                  Leveringskosten Enkeltarief <i><small>({{item.calculation.usage.consumption.single.usage}} kWh)</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.consumption.single.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.usage.consumption.single.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.marketSegment === 'GAS'"
              >
                <a-col
                    :xs="16"
                >
                  Leveringskosten <i><small>({{item.calculation.usage.consumption.single.usage}} m3)</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.consumption.single.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.usage.consumption.single.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'DOUBLE'"
              >
                <a-col
                    :xs="16"
                >
                  Leveringskosten Normaal <i><small>{{item.calculation.usage.consumption.high.usage}} kWh</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.consumption.high.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.usage.consumption.high.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'DOUBLE'"
              >
                <a-col
                    :xs="16"
                >
                  Leveringskosten Dal <i><small>{{item.calculation.usage.consumption.low.usage}} kWh</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.consumption.low.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(iitem.calculation.usage.consumption.low.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>

              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'SINGLE' && item.connection.marketSegment === 'ELECTRICITY' && item.calculation.usage.return.total !== 0"
              >
                <a-col
                    :xs="16"
                >
                  Teruglevering Enkel <i><small>({{item.calculation.usage.return.single.usage}} kWh)</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.return.single.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.usage.return.single.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'DOUBLE' && item.calculation.usage.return.total !== 0"
              >
                <a-col
                    :xs="16"
                >
                  Teruglevering Normaal <i><small>{{item.calculation.usage.return.high.usage}} kWh</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.return.high.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.usage.return.high.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.connection.meterType === 'DOUBLE' && item.calculation.usage.return.total !== 0"
              >
                <a-col
                    :xs="16"
                >
                  Teruglevering Dal <i><small>{{item.calculation.usage.return.low.usage}} kWh</small></i>
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.usage.return.low.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(iitem.calculation.usage.return.low.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>

              <section
                  style="width:100%;display: flex"
              >
                <a-col
                    :xs="16"
                >
                  Vaste leveringskosten
                </a-col>
                <a-col
                    :xs="8"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.fixed * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.fixed).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <a-col
                  :xs="24"
                  class="mt-1"
              >
                <strong>Netbeheerkosten</strong>
              </a-col>
              <a-col
                  :xs="18"
              >
                Netbeheerkosten
              </a-col>
              <a-col
                  :xs="6"
                  class="text-right"
              >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.grid.total * 1.21).toFixed(2)
                                }}</span>
                <span
                    v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                    parseFloat(item.calculation.grid.total).toFixed(2)
                  }}</span>
              </a-col>
              <a-col
                  :xs="24"
                  class="mt-1"
              >
                <strong>Belasting en toeslagen</strong>
              </a-col>
              <section
                  v-for="energyTaxItem in item.calculation.tax"
                  :key="energyTaxItem.componentType + String(Math.random())"
                  style="width:100%;display: flex"
              >
                <a-col
                    :xs="18"
                >
                  Energiebelasting <small>({{ energyTaxItem.from }} - {{ energyTaxItem.to }} &euro; {{
                    energyTaxItem.price
                  }}</small>)
                </a-col>
                <a-col
                    :xs="6"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(energyTaxItem.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(energyTaxItem.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  v-for="energyTaxItem in item.calculation.ode"
                  :key="energyTaxItem.componentType + String(Math.random())"
                  style="width:100%;display: flex"
              >
                <a-col
                    :xs="18"
                >
                  Opslag duurzame energie (<small>{{ energyTaxItem.from }} - {{ energyTaxItem.to }}
                  &euro; {{ energyTaxItem.price }}</small>)
                </a-col>
                <a-col
                    :xs="6"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(energyTaxItem.cost * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(energyTaxItem.cost).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <section
                  style="width:100%;display: flex"
                  v-if="item.calculation.reduction !== 0"
              >
                <a-col
                    :xs="18"
                >
                  Vermindering energiebelasting
                </a-col>
                <a-col
                    :xs="6"
                    class="text-right"
                >
                              <span
                                  v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                  parseFloat(item.calculation.reduction * 1.21).toFixed(2)
                                }}</span>
                  <span
                      v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                      parseFloat(item.calculation.reduction).toFixed(2)
                    }}</span>
                </a-col>
              </section>
              <a-col
                  :xs="24"
              >
                <hr>
              </a-col>
              <a-col
                  :xs="16"
              >
                <strong>Totaal (per jaar)</strong>
              </a-col>
              <a-col
                  :xs="8"
                  class="text-right"
              >
                            <span
                                v-if="type === 'CONSUMER' || calculations.product.isSoho === true">&euro; {{
                                parseFloat(item.calculation.total * 1.21).toFixed(2)
                              }}</span>
                <span
                    v-if="type === 'BUSINESS' && calculations.product.isSoho === false">&euro; {{
                    parseFloat(item.calculation.total).toFixed(2)
                  }}</span>
              </a-col>
            </a-row>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-collapse default-active-key="0" :bordered="false">
        <template #expandIcon="props">
          <sdFeatherIcons :type="props.isActive ? 'chevron-down' : 'chevron-right'" size="14"/>
        </template>
        <a-collapse-panel key="1" :style="customStyle">
          <template #header>
            <div class="lead collapse-title" style="width:100%;display:inline-block;">
              <i class="fas fa-euro-sign" style="float:left;font-size: 16px; color:#000000"></i>
              <h4 class="ml-3 mr-1" style="float:left">Tarieven</h4>
            </div>
          </template>
          <div>
            <a-row>
              <a-col
                  :xs="12"
                  class="mt-1"
              >
                &nbsp;
              </a-col>
              <a-col
                  :xs="4"
              >
                <span><small>Excl.</small></span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span><small>Incl.</small></span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span><small>Incl. ODE & EB</small></span>
              </a-col>
              <a-col
                  :xs="24"
                  class="mt-1"
              >
                <strong>Elektriciteit</strong>
              </a-col>
              <a-col
                  :xs="12"
                  style="margin-top:5px"
              >
                Enkeltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffSingle }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Normaaltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffHigh }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffHigh }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffHigh }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Daltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffLow }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffLow }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffLow }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Teruglevering enkeltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Teruglevering normaaltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffReturnSingle }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Teruglevering daltarief <small><i>(per kWh)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffReturnLow }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffReturnLow }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffReturnLow }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Vaste kosten <small><i>(per jaar)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffFixedEnergy }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffFixedEnergy }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&nbsp;</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                  :xs="24"
                  class="mt-1"
              >
                <strong>Gas</strong>
              </a-col>
              <a-col
                  :xs="12"
                  style="margin-top:5px"
              >
                Leveringstarief (G1) <small><i>(per m3)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffGas1 }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffGas1 }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffGas1 }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Leveringstarief (G2) <small><i>(per m3)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffGas2 }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffGas2 }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withTotalVat.tariffGas2 }}</span>
              </a-col>
              <a-col
                  :xs="12"
              >
                Vaste kosten <small><i>(per jaar)</i></small>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withoutVat.tariffFixedGas }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&euro;{{ calculations.consumerTariff.withVat.tariffFixedGas }}</span>
              </a-col>
              <a-col
                  :xs="4"
              >
                <span>&nbsp;</span>
              </a-col>
            </a-row>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-row
          class="mt-3"
          style="background-color:rgb(81 156 120);color:#ffffff;padding: 10px 10px;border-radius: 4px;"
      >
        <a-col
            :xs="16"
            style="padding-top:5px"
        >
          <strong>Jaarlijkse kosten ({{ type === 'CONSUMER' || calculations.product.isSoho === 0 ? 'incl.' : 'excl.' }} btw)</strong>
        </a-col>
        <a-col
            :xs="8"
            style="padding-top:5px"
            class="text-right"
        >
          <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>&euro; {{ parseFloat(calculations.calculation.summary.totalWithVat).toFixed(2) }}</strong></span>
          <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>&euro; {{ parseFloat(calculations.calculation.summary.total).toFixed(2) }}</strong></span>
        </a-col>
        <a-col
            :xs="16"
            style="padding-top:5px"
        >
          <strong>Maandelijkse kosten ({{ type === 'CONSUMER' || calculations.product.isSoho === 0 ? 'incl.' : 'excl.' }} btw)</strong>
        </a-col>
        <a-col
            :xs="8"
            style="padding-top:5px"
            class="text-right"
        >
          <span v-if="type === 'CONSUMER' || calculations.product.isSoho === true"><strong>&euro; {{ parseFloat(parseInt(calculations.calculation.summary.totalWithVat)/12).toFixed(2) }}</strong></span>
          <span v-if="type === 'BUSINESS' && calculations.product.isSoho === false"><strong>&euro; {{ parseFloat(parseInt(calculations.calculation.summary.total)/12).toFixed(2) }}</strong></span>
        </a-col>
        <a-col
            :xs="16"
            style="padding-top:5px"
        >
          <strong>btw</strong>
        </a-col>
        <a-col
            :xs="8"
            style="padding-top:5px"
            class="text-right"
        >
          <strong>&euro; {{ parseFloat((calculations.calculation.summary.total * 0.21)/12).toFixed(2) }}</strong>
        </a-col>
      </a-row>
    </sdCards>
  </Suspense>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
export default defineComponent({
  name: 'Aom',
  components: {
  },
  data() {
  },
  props: {
    calculations: VueTypes.object.def(null),
    type: VueTypes.oneOf(['BUSINESS', 'CONSUMER']).def('CONSUMER'),
    loading: VueTypes.bool.def(true),
  },
});
</script>
