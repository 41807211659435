<template>
  <CardToolbox>
    <sdPageHeader title="Uw aanbod">

    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row v-if="loadingOffer" :gutter="30">
      <a-col :xs="24">
        <div class="spin">
          <a-spin/>
        </div>
      </a-col>
    </a-row>
    <a-row v-visible="!loadingOffer" v-if="loadingOffer || (!loadingOffer && !loadingOfferError)" :gutter="30">

      <a-col :md="12" :xs="24" v-if="!offer?.isSignable && offer?.isExpired">
        <sdCards headless>
          Geachte {{offer?.relation?.fullName}},<br>
          <br>
          Hartelijk dank voor je offerte aanvraag, helaas is dit aanbod verlopen. Neem contact op voor een nieuw aanbod.
        </sdCards>
      </a-col>
      <a-col :md="12" :xs="24" v-if="!offer?.isSignable && offer?.isSigned">
        <sdCards headless>
          Geachte {{offer?.relation?.fullName}},<br>
          <br>
          Hartelijk dank voor uw bestelling van {{ offer?.product?.name }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw bestelling.
        </sdCards>
      </a-col>
      <a-col :md="12" :xs="24" v-if="offer?.isSignable">
        <sdCards headless>
          Geachte {{offer?.relation?.fullName}},<br>
          <br>
          Hartelijk dank voor je offerte aanvraag met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je namens
          {{ offer?.sender?.senderName }} een aantrekkelijk aanbod doen met {{ offer?.product?.name }}.

        </sdCards>

        <Aom v-if="!loadingOffer" :calculations="offer.energyCalculation"/>

        <sdCards title="Documenten">
          <a v-for="(item,index) in offer?.product?.files" :key="index" :href="item?.fileURL">
            {{ item?.name }}
          </a>
        </sdCards>
        <a-form :model="offer" layout="vertical" @finish="signOffer">
        <sdCards title="Ondertekenen">
            <a-row :gutter="30">
              <a-col :xs="24" class="mb-5">

                <a-form-item style="padding-bottom:0px;margin-bottom:0px" class="checkbox-group" v-for="(item,index) in offer?.product?.customerApprovals"
                             :key="index" label="" :name="['product','customerApprovalsValidation', index]" :rules="{ validator: checkCheckBox }">
                  <a-checkbox v-model:checked="offer.product.customerApprovalsValidation[index]" :name="['product','customerApprovalsValidation', index]">
                    {{item}}
                  </a-checkbox>
                </a-form-item>

              </a-col>
              <a-col v-if="!loadingOffer" :md="12" :xs="24" class="mb-5">
                <a-form-item label="IBAN" :name="['relation','bankAccountNumber']" :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-input v-model:value="offer.relation.bankAccountNumber" :name="['relation','bankAccountNumber']" placeholder=""/>
                </a-form-item>
              </a-col>
              <a-col v-if="!loadingOffer" :md="12" :xs="24" class="mb-5">
                <a-form-item label="Tenaamstelling" :name="['relation','debtorName']" :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-input v-model:value="offer.relation.debtorName" :name="['relation','debtorName']" placeholder=""/>
                </a-form-item>
              </a-col>
              <a-col :xs="24" class="signature align-center">
                  <Vue3Signature ref="signature1" :sigOption="sigState.option" :w="'450px'" :h="'200px'"
                                 :disabled="sigState.disabled" @change="alert('2')"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error"><div role="alert">Uw handtekening is vereist</div></div>
                  <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                    <span>Opnieuw</span>
                  </sdButton>
              </a-col>
            </a-row>
        </sdCards>
        <sdCards>
          <a-row>
            <a-col :sm="12" :xs="24">
              <div class="sDash_form-action">
                <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingSignOffer"
                          :disabled="loadingSignOffer" @click="validateSignature"> Accorderen
                </a-button>
<!--                <a-button size="large" class="btn-signin ml-5" type="danger" html-type="submit" :loading="loadingSignOffer"-->
<!--                          :disabled="loadingSignOffer"> Weigeren-->
<!--                </a-button>-->
              </div>
            </a-col>
          </a-row>
        </sdCards>
        </a-form>
      </a-col>
      <a-col :md="12" :xs="24">
        <div
            id="adobe-dc-view"
            style="width: 100%; box-shadow: 1px 1px 10px 1px #dadada;display: flex;height:90vh;flex-flow: column;"
        />

      </a-col>
    </a-row>
    <a-row  v-if="!loadingOffer && loadingOfferError" :gutter="30">
      <a-col :md="12" :xs="24">
        <sdCards headless>
          Geachte Heer/Mevrouw,<br>
          <br>
          Deze link waarmee je deze pagina probeert te bezoeken is incorrect of mogelijk verlopen. Contacteer de klantenservice voor meer informatie.
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {defineComponent, onMounted, reactive, computed, ref} from 'vue';
import {Main, CardToolbox} from '../styled';
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import Aom from "../../components/aom/Aom";
import {checkCheckBox} from "../../utility/validators";

const Sign = defineComponent({
  name: 'Sign',
  components: {Main, CardToolbox, Aom},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const offer = reactive(computed(() => state.customer.offer));
    const loadingOffer = reactive(computed(() => state.customer.loadingOffer));
    const loadingSignOffer = reactive(computed(() => state.customer.loadingSignOffer));
    const loadingOfferError = reactive(computed(() => state.customer.loadingOfferError));
    const signatureError = ref(false);
    const sigState = reactive({
      count: 0,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(220,220,220)",
      },
      disabled: false
    })

    const approvals = ref([]);

    const signature1 = ref()

    const clearSignature = () => {
      signature1.value.clear()
    }

    const validateSignature = () => {
      signatureError.value = signature1.value.isEmpty()
    }

    const signOffer = async () => {
      signatureError.value= false;
      if(!signature1.value.isEmpty()) {
        await dispatch('signOffer', {
          token: params.token,
          tenant: params.tenant,
          bankAccountNumber: offer.value.relation.bankAccountNumber,
          debtorName: offer.value.relation.debtorName,
          signatureData: signature1.value.save('image/svg+xml'),
        });
      } else {
        signatureError.value= true;
      }
    }

    onMounted(() => {
      dispatch('getOffer', params);
      const viewerConfig = {
        embedMode: 'SIZED_CONTAINER',
        showPageControls: true,
      }

      // eslint-disable-next-line no-undef
      const adobeDCView = new AdobeDC.View({
        clientId: 'xxx',
        divId: 'adobe-dc-view',
      })

      adobeDCView.previewFile({
        content: {
          location: {
            url: `https://dev.api.contrackt.cloud/customer/v1/${params.tenant}/offer/${params.token}/pdf`,
          },
        },
        metaData: {
          /* file name */
          fileName: `Aanbod.pdf`,
        },
      }, viewerConfig)
    });

    return {
      offer,
      loadingOffer,
      loadingOfferError,
      sigState,
      clearSignature,
      signature1,
      loadingSignOffer,
      signOffer,
      Aom,
      approvals,
      checkCheckBox,
      signatureError,
      validateSignature,
    };
  },
});

export default Sign;
</script>

<style>

.signature canvas {
  width: 450px;
  height: 200px;
}
.checkbox-group .ant-form-item-control-input {
  min-height: 0px !important;
}
</style>
