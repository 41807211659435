const routes = [
  {
    name: 'landing',
    path: '/',
    component: () => import(/* webpackChunkName: "order" */ '@/view/landing/Landing.vue'),
    meta: {
      action: 'read',
      resource: 'auth',
    },
  },
  {
    name: 'offer',
    path: '/:tenant/:token',
    component: () => import(/* webpackChunkName: "order" */ '@/view/sign/Sign.vue'),
    meta: {
      action: 'read',
      resource: 'auth',
    },
  },
];

export default routes;
